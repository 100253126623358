















import Day from '@/modules/common/types/day.type';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import MarketsCommonPopup from '@/modules/markets/components/markets-common-popup.vue';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: { MarketsCommonPopup },
})
export default class DayMarketModalPage extends Vue {
    @Inject(MarketsServiceS) protected marketsService!: MarketsService;
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        type: String,
    })
    private source?: string;

    get actualSource(): string | null {
        const { provider } = this.marketsService.settings;
        return this.source || provider;
    }

    get compsetId() {
        return this.documentFiltersService.settings.compsetId;
    }

    get document() {
        return this.marketsService.storeState.providersMarketsDocuments[this.actualSource!]!;
    }

    get hotelId() {
        return this.userService.currentHotelId;
    }

    get marketId() {
        return this.compsetsService.currentMarketId;
    }
}
